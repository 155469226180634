import * as XLSX from 'xlsx-js-style';

export const excelParser = () => {

  function exportDataWithSheetJS(fileName,headers,data)
  {
    console.log(XLSX)
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
    worksheet['!autofilter'] = { ref:`A1:${XLSX.utils.encode_col(headers.length - 1)}1` }
    // XLSX.utils.sheet_set_auto_filter(worksheet, `A1:${XLSX.utils.encode_col(headers.length - 1)}1`);

    var wscols = []
    wscols.push({wch:50});
    wscols.push({wch:17});
    wscols.push({wch:13});
    wscols.push({wch:10});
    wscols.push({wch:19});
    wscols.push({wch:18});
    wscols.push({wch:14});
    wscols.push({wch:16});

    
    worksheet['!cols'] = wscols;
    
    for(var i=0;i<headers.length;i++)
    {
      worksheet[XLSX.utils.encode_col(i)+"1"].s = {
        font: {
          //name: "Calibri",
          sz: 12,
          bold: true,
          //color: { rgb: "FFFFAA00" },
        },
      }; 
    }

    debugger;

    
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, fileName);
  }

  return {
    exportDataWithSheetJS
  };
};